html {
  box-sizing: border-box;
  text-size-adjust: 100%;
  height: 100%;
}

body {
  background: rgb(245, 246, 247);
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: rgb(41, 43, 44);
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

iframe {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: rgb(68, 68, 68);
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal;
}

h2 {
  margin-top: 0.5rem;
}

a {
  text-decoration: none;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

p {
  margin: 0 0 1rem;
}

.no-wrap {
  white-space: nowrap;
}

@media (max-width: 600px) {
  h2 {
    margin-top: 0.25rem;
  }
}
